/* @media screen and (min-width: 1500px) {
    .card-menu {
        width: 40% !important;
        height: 160px !important;
    }

    .card-menu-image {
        width: 100% !important;
        border-radius: 12px !important;
        margin: auto !important;
        transition-duration: 100ms !important;
        height: 130px !important;
    }

    .card-cart-product {
        padding: 6px 15px !important;
    }

    .card-menu-image-1 {
        width: 65px !important;
        border-radius: 12px !important;
        margin: auto !important;
        transition-duration: 100ms !important;
        height: 60px !important;
    }

    .cart .products .product {
        padding-bottom: 10px !important;
        padding-top: 10px !important;
    }

    .image-for-big {
        border-radius: 8px !important;
        width: 70px !important;
    }

    .cartpage-service-options {
        width: 27% !important;
        height: 93px !important;
    }

    .mycart-table-order-row {
        width: 50% !important;
    }

} */



@media screen and (min-width: 1600px) {
    .card-menu {
        height: 155px !important;
    }

    .card-menu-image {
        height: 135px !important;
    }

}