@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Optionally, style the input for better visibility */
/* input[type=number] {
  width: 100px;
  padding: 5px;
  font-size: 13px;
} */

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  color: black;
}

/* NavBar */
.navbar-mh {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  display: flex;
  border-bottom: 1px solid rgb(212, 212, 212);
}

.navbar-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.nav-location-logo-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}

.navbar-logo {
  width: 30%;
}

.navbar-location {
  width: 80%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.navbar-servicetyp-box {
  height: 37px;
  position: relative;
  border-radius: 10px;
  padding: 0px 10px;
  margin-right: 25px;
  border: 1px solid #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: none;
  font-weight: 600;
  font-size: 13px;
  color: #3d3d3d;
}

.navbar-down-arrow {
  margin-left: 5px;
  margin-top: 3px;
  position: absolute;
}

.navbar-location:hover .navbar-location-txt {
  color: rgb(153, 95, 8);
}

.navbar-location:hover .navbar-icon {
  color: rgb(153, 95, 8);
}

.navbar-location-txt {
  font-size: 12px;
  line-height: 15px;
  color: rgb(87, 87, 87);
}

.navbat-option-box {
  display: flex;
  width: 55%;
  justify-content: space-evenly;
  height: 100%;
}

.navbar-option {
  display: flex;
  cursor: pointer;
  color: black;
  align-items: center;
  padding: 15px;
  transition: all;
  text-decoration: none;
  /* transition-duration: 400Ms; */
}

.navbar-option:hover .navbar-option-link-txt {
  color: rgb(153, 95, 8);
}

.navbar-option:hover {
  background-color: rgb(248, 248, 248);
  /* border-radius:  30px; */
  /* border-top: 1px solid rgb(153, 95, 8); */
  border-bottom: 2px solid rgb(153, 95, 8);
}

.navbar-option:hover .navbar-icon {
  color: rgb(153, 95, 8);
}

.navbar-icon {
  margin-right: 8px;
}

/* .navbar-option-link{
 
} */

.navbar-option-link-txt {
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  color: rgb(87, 87, 87);
}

.navbar-icon {
  font-size: 14px;
  color: rgb(87, 87, 87);
}

/* .navbat-option-link{

} */

/* Footer */

.footer {
  width: 100%;
  position: relative;
  bottom: 0;
  height: 48vh;
  background-color: #000000da;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(221, 221, 221);
  font-size: 14px;
  color: white;
  /* margin-top: 10px; */
  height: 60px;
}

.footer-top {
  margin-top: 10px;
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.footer-top-part {
  width: 25%;
  position: relative;
}

.footer-text-header {
  font-size: 16px;
  margin-left: 40px;
  border-bottom: 1px solid rgb(219, 219, 219);
  padding-bottom: 10px;
  color: white;
}

.footer-text-link {
  font-size: 14px;
  line-height: 18px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 40px;
  cursor: pointer;
  color: white;
}

.footer-text-link:hover {
  color: rgb(192, 117, 19);
  text-shadow: 1px 1px 1px rgb(255, 255, 255);
}

.footer-text-header {
  margin-right: 30px;
}

.media-links {
  height: 100px;
}

/* imageslider */

.image-slider {
  width: 100%;
  height: 91vh;
  overflow: hidden;
  position: relative;
}

.slider-image {
  max-width: 100%;
  max-height: 120%;
}

.image-slider-button-box {
  position: absolute;
  z-index: 10;
  top: 48%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* padding: 10px; */
}

.image-slider-button {
  cursor: pointer;
  width: 50px;
  height: 50px;
  align-items: center;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  border: none;
  background-color: rgba(0, 0, 0, 0.589);
  color: white;
  margin: 20px;
  margin-top: 0;
  margin-bottom: 0;
}

/* product menu card  */

.image-for-big {
  border-radius: 8px;
}

.card-menu {
  overflow: visible;
  width: 44%;
  min-height: 140px;
  margin: 8px 10px;
  cursor: pointer;
  border-radius: 15px;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 0px 10px rgb(235, 235, 235);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* transition: all; */
  /* transition-duration: 300ms; */
  position: relative;
}

.card-menu:hover {
  scale: 1.05;
  border-radius: 15px;
  /* box-shadow: 1px 0px 10px rgb(221, 221, 221); */
}

.card-menu-1 {
  width: 94%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  transition: all;
  padding: 5px;
}

.card-menu-image {
  width: 100%;
  border-radius: 12px;
  margin: auto;
  transition-duration: 100ms;
  height: 110px;
}

.card-menu-image-1 {
  width: 60px;
  border-radius: 12px;
  margin: auto;
  transition-duration: 100ms;
  height: 55px;
}

.cart-menu-image {
  width: 85%;
  border-radius: 12px;
  margin: auto;
  transition-duration: 100ms;
}

.cart-image:hover {
  background-color: rgb(219, 219, 219);
  scale: 1.05;
}

.menu-image-box {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 40%;
  flex-direction: column;
}

.menu-image-box-1 {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 55%;
  flex-direction: column;
}

/* quantity box in ourmenu  */

.quantity {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 0;
}

.quantity__minus,
.quantity__plus {
  display: block;
  width: 25px;
  height: 25px;
  margin: 0;
  background: #ededed;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
  border: none;
  font-size: 20px;
}

.quantity__minus:hover,
.quantity__plus:hover {
  background: #575b71;
  color: #fff;
}

.quantity__minus {
  border-radius: 6px 0 0 6px;
}

.quantity__plus {
  border-radius: 0 6px 6px 0;
}

.quantity__input {
  width: 32px;
  height: 17px;
  margin: 0;
  padding-left: 0px;
  text-align: center;
  border-top: 3px solid #ededed;
  border-bottom: 3px solid #ededed;
  border-left: 0px solid #dee0ee;
  border-right: 0px solid #dee0ee;
  background: #fff;
  color: #8184a1;

}

.quantity__minus:link,
.quantity__plus:link {
  color: #8184a1;
}

.quantity__minus:visited,
.quantity__plus:visited {
  color: #fff;
}

.menu-card-title-name {
  margin: 0;
  font-size: 13px;
  color: #333;
  line-height: 17px;
  font-weight: 600;
  margin-bottom: 2px;
  padding-right: 20px;
}

.menu-open {
  display: none;
  width: 70px;
  height: 50px;
  position: absolute;
  bottom: 100px;
  background-size: contain;
  transform: translate(-50%, -60%);
  right: 0%;
  z-index: 10;
  background-image: url("https://mothershut.com/RestoFolders/MOTHERSHUT_Supela_Bhilai/btn_menu.png");
}

.menu-card-price {
  margin: 0;
  /* margin-top: 2px; */
  font-size: 12px;
  color: #5a5a5a;
  line-height: 14px;
  font-weight: 600;
  margin-bottom: 2px;
}

.menu-card-detail {
  margin: 0;
  font-size: 10px;
  color: #474747;
  line-height: 13px;
  width: 90%;
  /* margin-bottom: 3px; */
}

.quantity-box {
  margin-bottom: 5px;
  margin-top: 5px;
}

.card-menu-favorite {
  position: absolute;
  top: 10px;
  font-size: 16px;
  right: 10px;
}

.rupees-icon {
  font-size: 11px;
}

/* .large-font{
    font-size:xxx-large;
  } */
.top-20 {
  position: relative;
  top: 20vh;
}

ion-icon.active {
  animation: like 0.5s 1;
  fill: red;
  stroke: none;
}

ion-icon {
  fill: transparent;
  stroke: black;
  stroke-width: 30;
  transition: all 0.5s;
}

/* @-webkit-keyframes like {
    0%   { transform: scale(1); }
    90%   { transform: scale(1.2); }
    100% { transform: scale(1.1); }
  } */

/*  */

/*  product card  */

.product-card-box {
  width: 95%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
  margin-bottom: 70px;
}

.card-mh {
  display: flex;
  overflow: visible;
  width: 200px;
  height: 200px;
  margin: 20px;
  margin-bottom: 30px;
  cursor: pointer;
}

.content {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 500ms;
  box-shadow: 0px 0px 8px 1px #7a7a7ab6;
  border-radius: 5px;
}

.front,
.back {
  background-color: #151515;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border-radius: 5px;
  overflow: hidden;
}

.back {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.back::before {
  position: absolute;
  content: " ";
  display: block;
  width: 160px;
  height: 160%;
  background: linear-gradient(90deg,
      transparent,
      #ff9966,
      #ff9966,
      #ff9966,
      #ff9966,
      transparent);
  animation: rotation_481 5000ms infinite linear;
}

.back-content {
  position: absolute;
  width: 99%;
  height: 99%;
  border-radius: 5px;
  background-size: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.card-mh:hover .content {
  transform: rotateY(180deg);
}

@keyframes rotation_481 {
  0% {
    transform: rotateZ(0deg);
  }

  0% {
    transform: rotateZ(360deg);
  }
}

.front {
  transform: rotateY(180deg);
  color: white;
}

.front .front-content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.front-content .badge {
  background-color: #00000055;
  margin: 10px;
  padding: 2px 10px;
  border-radius: 10px;
  backdrop-filter: blur(2px);
  width: fit-content;
}

.description {
  box-shadow: 0px 0px 10px 5px #00000088;
  width: 100%;
  padding: 0px;
  background-color: #00000099;
  backdrop-filter: blur(5px);
  border-radius: 5px;
}

.title {
  font-size: 13px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 0px;
  justify-content: center;
  padding: 0 !important;
}

.title p {
  width: 90%;
  padding: 0;
}

.card-footer {
  color: #ffffff88;
  margin-top: 7px;
  font-size: 12px;
  margin-left: 0px;
  text-align: center;
  margin-bottom: 7px;
}

.front .img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.circle {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #ffbb66;
  position: relative;
  filter: blur(15px);
  animation: floating 2600ms infinite linear;
}

#bottom {
  background-color: #ff8866;
  left: 50px;
  top: 0px;
  width: 150px;
  height: 150px;
  animation-delay: -800ms;
}

#right {
  background-color: #ff2233;
  left: 160px;
  top: -80px;
  width: 30px;
  height: 30px;
  animation-delay: -1800ms;
}

@keyframes floating {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

/* search input  */

.container-input {
  position: relative;
}

.input {
  width: 180px;
  padding: 10px 0px 10px 40px;
  border-radius: 9999px;
  border: solid 1px #333;
  transition: all 0.2s ease-in-out;
  outline: none;
  opacity: 0.8;
}

.container-input svg {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
}

.input:focus {
  opacity: 1;
  width: 250px;
}

.search-input-homepage-box {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.homepage-srch-btn {
  cursor: pointer;
  width: 80px;
  height: 35px;
  align-items: center;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  border: none;
  background-color: rgba(0, 0, 0, 0.589);
  color: white;
  margin: 5px;
  margin-top: 0;
  margin-bottom: 0;
}

/* social media links  */

.social-menu ul {
  position: absolute;
  bottom: 00%;
  left: 50%;
  padding: 0;
  margin: 0;
  transform: translate(-50%, -50%);
  display: flex;
}

.social-menu ul li {
  list-style: none;
  margin: 0 12px;
}

.social-menu ul li .fab {
  width: 100%;
  line-height: 40px;
  transition: 0.3s;
  color: #000;
  font-size: 23px;
}

.social-menu ul li .fab:hover {
  color: #fff;
}

.social-menu ul li a {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  transition: 0.6s;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.5);
}

.social-menu ul li a:hover {
  transform: translate(0, -10%);
}

.social-menu ul li:nth-child(1) a:hover {
  background-color: rgba(27, 96, 187, 0.829);
}

.social-menu ul li:nth-child(2) a:hover {
  background-color: #e4405f;
}

.social-menu ul li:nth-child(3) a:hover {
  background-color: #1e990e;
}

.social-menu ul li:nth-child(4) a:hover {
  background-color: #be0000;
}

/* OurMenu Page    */

.ourmenu-page {
  display: flex;
  height: max-content;
}

.ourmenu-left-section {
  width: 22%;
  /* box-shadow: 5px 0px 10px rgb(231, 231, 231); */
}

.scrollpart-cart-2 {
  overflow: scroll;
  height: 84vh;
}

.ourmenu-left-section {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.displaynone {
  display: none;
}

.scrollpart-cart-2::-webkit-scrollbar {
  display: none;
}

.ourmenu-right-section {
  width: 50%;
  height: 91vh;
  overflow: scroll;
  padding: 0;
  box-shadow: 0px 5px 5px rgb(231, 231, 231);
}

.ourmenu-right-section {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.ourmenu-right-section::-webkit-scrollbar {
  display: none;
}

.ourmenu-cart-section {
  width: 28%;
  height: 91vh;
  position: relative;
}

.ourmenu-cart-section {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.ourmenu-cart-section::-webkit-scrollbar {
  display: none;
}

.ourmenu-heading-submenu-head {
  width: 98%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  background-color: #f8a221;
  color: rgb(255, 255, 255);
  padding: 3px 0px;
  border-radius: 5px;
  font-size: 16px;
}

.ourmenu-heading-submenu {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #cdcdcd; */
  margin-top: 0px;
  background-color: #e5e5e5;
  color: #5f5f5f;
  padding: 5px 0px;
  border-radius: 0px;
}

/* .ourmenu-filter{

} */

.ourmenu-product-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* padding-bottom: 15px;
  padding-top: 15px; */
}

.ourmenu-topdiv {
  height: 45px;
  width: 100%;
  outline: 1px solid rgb(218, 218, 218);
  /* border-top: 1px solid rgb(218, 218, 218); */
  /* border-bottom: 1px solid rgb(218, 218, 218); */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.product-category-head {
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 13px;
  background: rgba(248, 159, 26, 0.97);
  color: rgb(255, 255, 255);
  /* position: sticky;
    top: 0px; */
  box-shadow: 0px 5px 10px rgb(226, 226, 226);
}

.product-category {
  text-align: center;
  margin: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
}

.catogory-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #efefef;
}

.catogory-box:hover {
  text-align: center;
  background-color: #dbdbdb;
}



/* .catogory-box:active{
 background-color: #dbdbdb;
} */

/* .catogory-box:focus{
  text-align: center;
 background-color: #dbdbdb;
} */

.card-cart-product {
  overflow: visible;
  width: 90%;
  height: max-content;
  margin: auto;
  margin-bottom: 0px;
  margin-top: 0px;
  cursor: pointer;
  border-bottom: 1px solid rgb(212, 212, 212);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all;
  transition-duration: 300ms;
  position: relative;
  padding: 8px 16px;
  background-color: white;
}

.product-category-foot {
  font-size: 13px;
  width: 100%;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  background: rgba(38, 151, 4, 0.966);
  color: rgb(255, 255, 255);
  position: absolute;
  bottom: 0px;
  box-shadow: 0px 5px 10px rgb(226, 226, 226);
}

.product-category-foot-1 {
  font-size: 13px;
  width: 91.32%;
  padding-top: 8px;
  padding-bottom: 8px;
  background: rgba(255, 255, 255, 0.966);
  color: rgb(0, 0, 0);
  position: absolute;
  bottom: 35px;
  border: 1px solid rgb(231, 231, 231);
  height: 40px;
  padding: 15px;
}

.scrollpart-cart {
  position: relative;
  height: 67vh;
  overflow: scroll;
}

.scrollpart-cart {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollpart-cart::-webkit-scrollbar {
  display: none;
}

.card-cartimage-div {
  width: 17%;
  height: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card-cart-content-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.card-cart-txt-1 {
  color: rgb(56, 56, 56);
  font-size: 13px !important;
  line-height: 15px !important;
}

.card-cart-txt-2 {
  font-size: 12px;
  color: rgb(109, 109, 109);
}

.card-cart-txt-3 {
  font-size: 14px;
  width: 20px;
  color: rgb(56, 56, 56);
  display: flex;
  align-items: center;
}

.cart-card-detail-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 0px;
  width: 40%;
}

.cart-card-detail-box-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 0px;
  width: 20%;
}

td {
  height: 40px;
  border: 1px solid rgb(209, 209, 209);
}

.favroit-table {
  border-collapse: collapse;
  width: 90%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  height: max-content;
  border: 1px solid rgb(209, 209, 209);
}

th {
  height: 50px;
  border: 1px solid rgb(209, 209, 209);
}

/* my account modal  */

.modal-back {
  position: fixed;
  width: 100%;
  height: 1500vh;
  top: 0;
  left: 0;
  background: rgba(59, 59, 59, 0.705);
  animation: modalback 500ms;
  z-index: 100;
}

@keyframes modalback {
  0% {}

  100% {}
}

.modal-back-loader {
  position: fixed;
  width: 100%;
  height: 1500vh;
  top: 0;
  left: 0;
  background: rgba(75, 75, 75, 0.178);
  animation: modal-back-loader 500ms;
  z-index: 100;
}

@keyframes modal-back-loader {
  0% {}

  100% {}
}

.account-modal {
  width: 30%;
  height: max-content;
  border: 1px solid rgba(94, 94, 94, 0.822);
  color: rgb(0, 0, 0);
  background-color: rgb(56, 56, 56);
  border-radius: 8px;
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -60%);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: modalslide 700ms;
}

.logout-modal {
  width: 20%;
  min-width: 300px;
  height: max-content;
  border: 1px solid rgba(94, 94, 94, 0.822);
  color: rgb(0, 0, 0);
  background-color: rgb(56, 56, 56);
  border-radius: 8px;
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -130%);
  animation: modalslide 700ms;
}

.form-container {
  width: 100%;
  border-radius: 0.75rem;
  background-color: rgba(17, 24, 39, 1);
  padding: 2rem;
  color: rgba(243, 244, 246, 1);
  height: 90%;
  padding-bottom: 5px;
  padding-top: 10px;
}

.title {
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

.form {
  margin-top: 1.2rem;
}

.input-group {
  position: relative;
  margin-top: 0.25rem;
  font-size: 0.8rem;
  line-height: 1.25rem;
}

.input-group label {
  display: block;
  color: rgba(156, 163, 175, 1);
  margin-bottom: 4px;
}

.input-group input {
  width: 91.5%;
  border-radius: 0.375rem;
  border: 1px solid rgba(55, 65, 81, 1);
  outline: 0;
  background-color: rgba(17, 24, 39, 1);
  padding: 0.7rem 0.5rem;
  color: rgba(243, 244, 246, 1);
}

.input-group input:focus {
  border-color: rgba(167, 139, 250);
}

.forgot {
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgba(156, 163, 175, 1);
  margin: 8px 0 14px 0;
  cursor: pointer;
}

.forgot a,
.signup a {
  color: rgba(243, 244, 246, 1);
  text-decoration: none;
  font-size: 14px;
}

.forgot a:hover,
.signup a:hover {
  text-decoration: underline rgba(167, 139, 250, 1);
}

.sign {
  display: flex;
  width: 97%;
  background-color: rgba(167, 139, 250, 1);
  text-align: center;
  color: rgb(10, 10, 10);
  height: 35px;
  border: none;
  border-radius: 0.375rem;
  font-weight: 600;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.sign-no {
  display: flex;
  width: 93%;
  background-color: rgb(199, 12, 52);
  /* padding: 0.75rem; */
  text-align: center;
  font-size: 13px;
  color: rgb(255, 255, 255);
  height: 35px;
  border: none;
  border-radius: 0.375rem;
  font-weight: 600;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}

.social-message {
  display: flex;
  align-items: center;
  padding-top: 1rem;
}

.line {
  height: 1px;
  flex: 1 1 0%;
  background-color: rgba(55, 65, 81, 1);
}

.social-message .message {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(156, 163, 175, 1);
}

.social-icons {
  display: flex;
  justify-content: center;
}

.social-icons .icon {
  border-radius: 0.125rem;
  padding: 0.75rem;
  border: none;
  background-color: transparent;
  margin-left: 8px;
}

.social-icons .icon svg {
  height: 1.25rem;
  width: 1.25rem;
  fill: #fff;
}

.signup {
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgba(156, 163, 175, 1);
}

.navbar-mobile {
  display: none;
}

.mobile-sidebar {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

td {
  text-align: center;
}

/* 

search bar
*/

.InputContainer {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  font-size: 13px;
  padding-left: 15px;
  border: 1px solid #d8d8d8;
  margin-right: 20px;
  /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075); */
}

.inputsearch {
  width: 170px;
  height: 100%;
  border: none;
  outline: none;
  font-size: 0.9em;
  caret-color: rgb(255, 81, 0);
}

.labelforsearch {
  cursor: text;
  padding: 0px 12px;
}

.searchIcon {
  width: 13px;
}

.border {
  height: 40%;
  width: 1.3px;
  background-color: rgb(223, 223, 223);
}

.micIcon {
  width: 12px;
}

.micButton {
  padding: 0px 15px 0px 12px;
  border: none;
  background-color: transparent;
  height: 40px;
  cursor: pointer;
  transition-duration: 0.3s;
}

.searchIcon path {
  fill: rgb(114, 114, 114);
}

.micIcon path {
  fill: rgb(255, 81, 0);
}

.micButton:hover {
  background-color: rgb(255, 230, 230);
  transition-duration: 0.3s;
}

/* myaccountpage */

.myaccountpage {
  width: 100%;
  display: flex;
  height: 90vh;
}

.myaccountpage-left {
  width: 22%;
  height: 90vh;
  border-right: 1px solid rgb(218, 218, 218);
}

.myaccountpage-right {
  width: 78%;
  height: 90vh;
}

/* cart page */

.cart-page {
  width: 100%;
  display: flex;
  background-color: #f3f3f355;
  padding-bottom: 20px;
}

.cartpage-left-section {
  width: 55%;
  /* height: 90vh; */
  /* overflow: scroll; */
  scrollbar-width: none;
  background-color: #f7f7f7;
  margin-bottom: 0px;
}

.cartpage-right-section {
  width: 45%;
  /* height: 90vh; */
  background-color: #f7f7f7;
  /* overflow: scroll; */
  -ms-overflow-style: none;
  /* box-shadow: 1px 2px 10px rgb(224, 224, 224); */
  position: relative;
}

.cartpage-left-section::-webkit-scrollbar {
  display: none;
}

.cartpage-right-section::-webkit-scrollbar {
  display: none;
}

.cart-left-sections1 {
  width: 80%;
  height: max-content;
  border-radius: 15px;
  /* box-shadow: 1px 1px 5px rgb(197, 197, 197); */
  border: 1px solid rgb(216, 216, 216);
  margin: 25px auto;
  background-color: white;
  padding: 20px 0px;
}

.cart-left-sections2 {
  width: 95%;
  height: 40vh;
  border-radius: 15px;
  border: 1px solid rgb(216, 216, 216);
  margin: 25px auto;
  background-color: white;
}

.cart-left-sections3 {
  width: 95%;
  height: 35vh;
  border-radius: 15px;
  border: 1px solid rgb(216, 216, 216);
  margin: 25px auto;
  background-color: white;
}

.cart-left-sections4 {
  width: 95%;
  height: 20vh;
  border-radius: 15px;
  border: 1px solid rgb(216, 216, 216);
  margin: 25px auto;
  background-color: white;
}

.master-container {
  display: grid;
  grid-template-columns: auto;
  /* gap: 5px; */
  width: 100%;
  padding: 00px;
  padding-top: 15px;
  background-color: #f7f7f7;
  height: 100%;
}

.card-cart {
  width: 60%;
  position: relative;
  /* margin: auto;   */
  margin-bottom: 20px;
  background: #ffffff;
  padding: 10px;
  box-shadow: 5px 5px 10px rgb(231, 231, 231);
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1); */
}

.card-cart::-webkit-scrollbar {
  display: none;
}

.title {
  width: 100%;
  height: 30px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-weight: 700;
  font-size: 16px;
  color: #63656b;
}

/* cart */
.cart {
  border-radius: 19px 19px 7px 7px;
}

.cart .products {
  display: flex;
  flex-direction: column;
  padding: 4px 20px;
}

/* .cart .products .product {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  border-bottom: 1px solid rgb(231 231 231);
  padding-bottom: 10px;
  transition: all;
  transition-duration: 300ms;
} */


.cart .products .product {
  width: 100%;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding-bottom: 5px;
  padding-top: 5px;
  transition: all;
  border-radius: 8px;
  transition-duration: 300ms;
  box-shadow: 0px 5px 15px #e9e9e9;
}



/* .cart .products .product:hover{
  scale: 1.01;
} */

.cart .products .product span {
  font-size: 14px;
  font-weight: 600;
  color: #47484b;
  margin-bottom: 8px;
  display: block;
  margin: 0;
}

.cart .products .product p {
  font-size: 12px;
  font-weight: 600;
  color: #7a7c81;
  margin: 0;
}

/* .cart .quantity {
    height: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: auto;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 7px;
    filter: drop-shadow(0px 1px 0px #efefef)
      drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  }

  .cart .quantity label {
    width: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    font-size: 15px;
    font-weight: 700;
    color: #47484b;
  }

  .cart .quantity button {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    outline: none;
    background-color: transparent;
    padding-bottom: 2px;
  } */

.card .small {
  font-size: 15px;
  margin: 0 0 auto auto;
}

.card .small sup {
  font-size: px;
}

/* coupons */
.coupons {
  border-radius: 7px;
}

.coupons .form {
  display: grid;
  grid-template-columns: 1fr 100px;
  gap: 10px;
  padding: 10px;
  padding-top: 20px;
  margin: 0;
}

.input_field {
  width: auto;
  height: 36px;
  padding: 0 0 0 12px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.input_field:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px #242424;
  background-color: transparent;
}

.coupons .form button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 10px;
  width: 100%;
  height: 36px;
  background: linear-gradient(180deg, #4480ff 0%, #115dfc 50%, #0550ed 100%);
  box-shadow: 0px 0.5px 0.5px #efefef, 0px 1px 0.5px rgba(239, 239, 239, 0.5);
  border-radius: 5px;
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}

/* Checkout */
.checkout {
  border-radius: 9px 9px 19px 19px;
}

.checkout .details {
  display: grid;
  grid-template-columns: 3fr 1fr;
  padding: 10px;
  gap: 5px;
}

.checkout .details span {
  font-size: 13px;
  font-weight: 600;
}

.checkout .details span:nth-child(odd) {
  font-size: 13px;
  font-weight: 700;
  color: #707175;
  margin: auto auto auto 0;
}

.checkout .details span:nth-child(even) {
  font-size: 14px;
  font-weight: 600;
  color: #47484b;
  margin: auto 0 auto auto;
}

.checkout .checkout--footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 7px 7px 20px;
  background-color: #efeff3;
  border-radius: 15px;
}

.price {
  position: relative;
  font-size: 13px;
  color: #2b2b2f;
  font-weight: 900;
}

.price sup {
  font-size: 13px;
}

.price sub {
  width: fit-content;
  position: absolute;
  font-size: 11px;
  color: #5f5d6b;
  bottom: 5px;
  display: inline-block;
}

.checkout .checkout-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 30px;
  background: linear-gradient(180deg, #4480ff 0%, #115dfc 50%, #0550ed 100%);
  box-shadow: 0px 0.5px 0.5px #efefef, 0px 1px 0.5px rgba(239, 239, 239, 0.5);
  border-radius: 7px;
  border: 0;
  outline: none;
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.add-to-cart-button-fav {
  border: none;
  font-size: 14px;
  background-color: white;
  color: navy;
}

.myfav-txt {
  font-size: 15px;
}

.products-left {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* width: 10%; */
  gap: 15px;
}

.products-right-small {
  width: 16%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
}

.products-right {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}


.favourite-add {
  font-size: 15px;
  color: rgb(51, 51, 51);
  cursor: pointer;
}

.favourite-delete {
  font-size: 15px;
  cursor: pointer
}


.form1 {
  --width-of-input: 200px;
  --border-height: 1px;
  --border-before-color: rgba(221, 221, 221, 0.39);
  --border-after-color: #5891ff;
  --input-hovered-color: #4985e01f;
  position: relative;
  width: 65%;
}

/* styling of Input */
.input1 {
  color: #000000;
  font-size: 0.9rem;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;
  padding-inline: 0.5em;
  padding-block: 0.7em;
  border: none;
  border-bottom: 1px solid rgba(100, 100, 100, 0.39);
}

/* styling of animated border */
.input-border1 {
  position: absolute;
  background: #3184ffbe;
  width: 0%;
  height: 2px;
  bottom: 0;
  left: 0;
  transition: 0.3s;
}

/* Hover on Input */
.input1:hover {
  background: #2c80ff1f;
}

.input1:focus {
  outline: none;
}

/* here is code of animated border */
.input1:focus~.input-border1 {
  width: 100%;
}

/* === if you want to do animated border on typing === */
/* remove input:focus code and uncomment below code */
/* input:valid ~ .input-border{
   width: 100%;
 } */

.buttonbox-logout {
  display: flex;
  gap: 15px;
}

.personal-info-text-box {
  width: 90%;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  /* margin-bottom: 20px; */
}

.pi-main-box {
  width: 80%;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.button-box-pi {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 60px;
}

.button-pi {
  width: 120px;
  padding: 10px;
  background: #3183ffe3;
  border: 0;
  cursor: pointer;
  font-size: 15px;
  color: white;
  border-radius: 12px;
  transition: all;
  transition-duration: 400ms;
}

.button-pi:hover {
  box-shadow: 0px 2px 10px rgb(70, 70, 70);
}

.address-box {
  height: max-content;
  margin: auto;
  margin-top: 15px;
  border: 1px solid rgb(199, 199, 199);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 7px 60px;
  background-color: #f3f3f3;
  position: relative;
  transition: all;
  transition-duration: 400ms;
  width: 75%
}

.address-box:hover {
  scale: 1.03;
}

.address-radio {
  margin: 0;
  margin-bottom: 5px;
}

.address-text {
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  color: #383838;
  width: 90%;
}

.address-logo {
  position: absolute;
  top: 38%;
  left: 20px;
  font-size: 22px;
  color: rgb(37, 37, 37);
}

.adress-delete-edit {
  position: absolute;
  top: 38%;
  right: 20px;
  font-size: 15px;
  display: flex;
  gap: 20px;
  cursor: pointer;
}

.custom-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  color: #333;
  transition: color 0.3s;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox .checkmark {
  width: 12px;
  height: 12px;
  border: 2px solid #333;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  transition: background-color 0.3s, border-color 0.3s, transform 0.3s;
  transform-style: preserve-3d;
}

.custom-checkbox .checkmark::before {
  content: "\2713";
  font-size: 16px;
  color: transparent;
  transition: color 0.3s, transform 0.3s;
}

.custom-checkbox input[type="checkbox"]:checked+.checkmark {
  background-color: #333;
  border-color: #333;
  transform: scale(1.1) rotateZ(360deg) rotateY(360deg);
}

.custom-checkbox input[type="checkbox"]:checked+.checkmark::before {
  color: #fff;
}

.custom-checkbox:hover {
  color: #666;
}

.custom-checkbox:hover .checkmark {
  border-color: #666;
  background-color: #f0f0f0;
  transform: scale(1.05);
}

.custom-checkbox input[type="checkbox"]:focus+.checkmark {
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2);
  outline: none;
}

.custom-checkbox .checkmark,
.custom-checkbox input[type="checkbox"]:checked+.checkmark {
  transition: background-color 1.3s, border-color 1.3s, color 1.3s,
    transform 0.3s;
}

.add-address-button {
  width: 130px;
  padding: 7px;
  font-size: 14px;
  background-color: rgb(51, 51, 51);
  color: white;
  border: none;
  border-radius: 15px;
  position: absolute;
  right: 40px;
  cursor: pointer;
  top: 12px;
  z-index: 5;
  transition: transform 300ms;
  transform-origin: center;
  transform: rotate(0deg);
}

.add-address-button:hover {
  scale: 1.1;
}

.order-box-top-top {
  width: 100%;
}

.my-order-table {
  width: 94%;
  height: 135px;
  border: 1px solid rgb(223, 223, 223);
  margin: auto;
  margin-top: 10px;
  border-radius: 15px;
  display: flex;
  overflow: hidden;
}

.order-detail-list {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.custom-div {
  height: max-content !important;
  transition: all;
  transition-duration: 700ms;
}

.ordertxt-1 {
  font-size: 13px;
  margin: 0;
  font-weight: 600;
}

.ordertxt-2 {
  font-size: 13px;
  margin: 0;
}

.ordertxt-3 {
  font-size: 12px;
  margin: 0px;
  font-weight: 600;
}

.order-box-top {
  display: flex;
  width: 96%;
  justify-content: space-between;
  margin: 10px 15px;
}

.cartpage-mastar-container {
  width: 100%;
  padding: 00px;
  padding-top: 15px;
  background-color: #f7f7f7;
  height: 100%;
  padding-bottom: 20px;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.cartpage-right-section-1 {
  width: 90%;
  background-color: white;
  margin: auto;
  padding: 12px 15px;
  border-radius: 15px;
  margin-top: 20px;
  box-shadow: 5px 5px 10px rgb(231, 231, 231);
  padding-bottom: 0;
  padding-top: 5px;
}

.cartpage-right-section-2 {
  width: 90%;
  background-color: white;
  margin: auto;
  padding: 15px 15px 20px 15px;
  border-radius: 15px;
  margin-top: 15px;
  box-shadow: 5px 5px 10px rgb(231, 231, 231);
  padding-bottom: 0;
  position: relative;
}

/* .cartpage-right-section-2 {
  position: absolute;
  right: 30px;
  top: 30px;
  border: none;
  padding: 10px 18px;
  background: linear-gradient(180deg, #4480ff 0%, #115dfc 50%, #0550ed 100%);
  color: white;
  font-size: 12px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  z-index: 1;
} */

.cartpage-right-section-2-button {
  position: absolute;
  right: 30px;
  top: 30px;
  border: none;
  padding: 10px 18px;
  background: linear-gradient(180deg, #4480ff 0%, #115dfc 50%, #0550ed 100%);
  color: white;
  font-size: 12px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  z-index: 1;
}

.cartpage-right-section-2 button:hover {
  scale: 1.05;
}

.cartpage-right-section-3 {
  width: 87%;
  background-color: white;
  margin: auto;
  padding: 25px;
  border-radius: 15px;
  margin-top: 15px;
  box-shadow: 5px 5px 10px rgb(231, 231, 231);
  padding-bottom: 0;
}

/* .cartpage-right-section-heading {
} */

.cartpage-service-options-box {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  padding-bottom: 15px;
  flex-wrap: wrap;
  padding-top: 10px;
  margin-top: 10px;
}

.cartpage-service-options-box-1 {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  padding-bottom: 15px;
}

.dis-fl-fl-co {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dis-fl-fl-co p {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cartpage-service-options {
  width: 30%;
  height: 90px;
  border-radius: 12px;
  background-color: rgb(243, 243, 243);
  border: 1px solid rgb(223, 223, 223);
  padding: 5px;
  cursor: pointer;
}

.cartpage-service-options-2 {
  width: 34%;
  height: 60px;
  border-radius: 12px;
  background-color: rgb(243, 243, 243);
  border: 1px solid rgb(223, 223, 223);
  padding: 5px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cartpage-service-options-2:hover {
  scale: 1.05;
}

.cartpage-service-options-2 p {
  color: #444444;
  font-weight: 600 !important;
}

.cartpage-service-options-2 .f-15 {
  color: #568f00;
  font-weight: 600 !important;
}

.cartpage-service-options:hover {
  scale: 1.02;
  box-shadow: 0px 0px 5px rgb(187, 187, 187);
}

.cartpage-service-options-1 {
  width: 43%;
  border-radius: 12px;
  background-color: rgb(243, 243, 243);
  border: 1px solid rgb(223, 223, 223);
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}

.cartpage-service-options-1:hover {
  scale: 1.02;
  box-shadow: 0px 0px 5px rgb(187, 187, 187);
}

.f-8 {
  font-size: 8px !important;
}

.f-9 {
  font-size: 9px !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-19 {
  font-size: 19px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-21 {
  font-size: 21px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-23 {
  font-size: 23px !important;
}

.f-24 {
  font-size: 24px !important;
}

/* .cartpage-service-p {
} */

.cartpage-service-options input {
  margin: 0;
  height: 15px;
  width: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 1px;
  font-weight: 600 !important;
}

.cartpage-service-options-1 input {
  margin: 0;
  height: 15px;
  width: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 1px;
  font-weight: 600 !important;
}

.cartoage-input-box p {
  color: #444444;
  font-weight: 600 !important;
}

.cartoage-input-box-1 p {
  color: #444444;
  font-weight: 600 !important;
}

.cartoage-input-box {
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
  margin-top: 3px;
  flex-direction: column;
}

.cartoage-input-box-1 {
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
  margin-top: 3px;
  flex-direction: column;
}

.service-type-paragraph {
  margin: 0px 0px;
  font-size: 11px;
  font-weight: 600;
  color: rgb(96 96 96);
}

.mycart-table-order {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.mycart-table-order-row {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.mycart-table-order-row select {
  border: none;
  margin: 5px;
  border-bottom: 1px solid rgb(100, 100, 100);
  font-size: 14px;
  font-family: poppins;
  width: 200px;
  color: rgb(100, 100, 100);
  padding: 3px;
}

.button-container-bottom {
  display: none;
  background-color: white;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px,
    rgba(245, 73, 144, 0.5) 5px 10px 15px;
}

.fw-600 {
  font-weight: 600;
}

.myordercart-mobile {
  display: none;
}

.main-modal-mothers-hut {
  width: 550px;
  height: max-content;
  border: 1px solid rgba(94, 94, 94, 0.822);
  color: rgb(0, 0, 0);
  background-color: rgba(17, 24, 39, 1);
  border-radius: 8px;
  position: fixed;
  top: 45%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -250px);
  animation: modalslide 700ms;
  flex-direction: column;
  padding: 35px 20px;
}

.main-modal-section-1 {
  display: flex;
  width: 88%;
  justify-content: space-between;
  color: rgba(156, 163, 175, 1);
  border: 1px solid #4d4d4d8f;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 13px;
}

.section-1-flex {
  display: flex;
  width: 80%;
  /* justify-content: flex-end; */
  justify-content: space-evenly;
  font-size: 13px;
}

.section-1-flex div {
  cursor: pointer;
  display: flex;
  align-items: center;
  /* margin: 0px 10px; */
}

.section-1-flex div input {
  margin: 0;
  margin-right: 5px;
}

.section-1-flex div p {
  margin: 0;
}

.main-modal-section-2 {
  /* display: flex; */
  width: 88%;
  justify-content: space-between;
  color: rgba(156, 163, 175, 1);
  border: 1px solid #4d4d4d8f;
  padding: 6px 15px;
  flex-direction: column;
  margin-bottom: 10px;
  height: 60px;
  font-size: 13px;
}

.main-modal-section-4 {
  display: flex;
  width: 88%;
  /* justify-content: space-between; */
  color: rgba(156, 163, 175, 1);
  border: 1px solid #4d4d4d8f;
  flex-direction: column;
  padding: 6px 15px;
  margin-bottom: 5px;
  /* height: 100px; */
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 13px;
}

.main-modal-section-3 {
  display: flex;
  width: 88%;
  /* justify-content: space-between; */
  color: rgba(156, 163, 175, 1);
  border: 1px solid #4d4d4d8f;
  flex-direction: column;
  padding: 6px 15px;
  margin-bottom: 5px;
  height: 80px;
  font-size: 13px;
}

.main-modal-section-2 select {
  width: 90%;
  font-size: 12px;
  padding: 5px 8px;
  background-color: transparent;
  color: rgba(156, 163, 175, 1);
  border: none;
  border-bottom: 1px solid rgba(55, 65, 81, 1);
}

.main-modal-section-2 .select-k1 {
  display: flex;
  justify-content: center;
}

.select-k1 select {
  width: 90%;
  font-size: 13px;
  margin: auto;
  padding: 5px 8px;
  background-color: transparent;
  color: rgba(156, 163, 175, 1);
  border: none;
  border-bottom: 1px solid rgba(55, 65, 81, 1);
}

.select-k1 label {
  width: 80%;
  padding: 5px 8px;
  padding-bottom: 0;
  margin: auto;
}

.main-modal-section-3 .select-k1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.main-modal-button-1 {
  width: 91%;
  margin-top: 10px;
  height: 30px;
  color: rgba(17, 24, 39, 1);
  font-size: 12px;
  font-weight: 600;
  background-color: rgba(167, 139, 250, 1);
  border: none;
  border-radius: 5px;
}

.main-modal-button-1:hover {
  scale: 1.01;
  cursor: pointer;
}

.xmark-ms-2 {
  display: none;
}

.terms-and-conditions {
  width: 85%;
  margin: auto;
  margin-top: 40px;
}

.terms-and-conditions h1 {
  font-size: 20px;
  margin-bottom: 10px;
  color: rgb(0, 0, 88);
}

.terms-and-conditions h2 {
  font-size: 16px;
  margin-bottom: 5px;
  color: #4b4b4b;
}

.terms-and-conditions p {
  font-size: 14px;
  margin-bottom: 10px;
  color: rgb(66, 66, 66);
  margin-top: 0;
}

.terms-and-conditions div {
  margin-bottom: 30px;
}

.strong-1 {
  width: 100%;
  text-align: center;
  background: #000000b6;
  padding: 4px 0px;
  border-radius: 0px;
  font-size: 13px;
  font-weight: 100;
  position: absolute;
  bottom: 0;
}

strong {
  font-size: 11px;
  color: rgb(255, 255, 255);
}

/* 
.badge-1 {
  background-color: #ffffff9a;
  margin: 10px;
  padding: 5px 8px;
  border-radius: 50px;
  backdrop-filter: blur(2px);
  width: fit-content;
  position: absolute;
  top: 0px;
  right:0px;
  color: black;
} */

/* search page for mobile */

.mobile-filter-page {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f4fdff;
  /* // background-color: rgb(255, 255, 255); */
  z-index: 101;
  display: none;
}

.filter-back-button {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 19px;
  /* color: $Mobile-icon-color; */
  border-radius: 50px;
}

.search-input-searchpanel {
  width: 80%;
  height: 32px;
  border: 1.25px solid #000042;
  margin-top: 7px;
  margin-bottom: 8px;
  border-radius: 8px;
  margin-right: 15px;
  padding-left: 10px;
  font-size: 13px;
}

.searchpanel-input-box {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  border-bottom: 1px solid rgb(238, 238, 238);
  /* background-color: #f0f0f6; */
  background-color: #ffffff;
}

.search-options-panal {
  height: max-content;
}

.search-back-button {
  position: absolute;
  top: 13px;
  left: 14px;
  font-size: 22px;
  color: rgb(0, 0, 0);
  border-radius: 50px;
}

/* Add your existing styles here */

.navbar-option.active .navbar-option-link-txt {
  color: rgb(153, 95, 8);
}

.navbar-option.active {
  background-color: rgb(248, 248, 248);
  /* border-radius:  30px; */
  /* border-top: 1px solid rgb(153, 95, 8); */
  border-bottom: 2px solid rgb(153, 95, 8);
}

.navbar-option.active .navbar-icon {
  color: rgb(153, 95, 8);
}

.form-container-2 {
  width: 40%;
  min-width: 300px;
  height: 480px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
}

.form-container-1 {
  width: 28%;
  min-width: 300px;
  height: 480px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
}

.coupon-box-1 {
  width: 85%;
  height: 80px;
  margin: auto;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgb(240, 240, 240);
  margin-bottom: 10px;
  display: flex;
  border: 1px solid rgb(187, 187, 187);
  align-items: center;
  padding: 0px 20px;
  justify-content: space-between;
}

.coupon-box-2 {
  width: 95%;
  height: max-content;
  margin: auto;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgb(240, 240, 240);
  margin-bottom: 10px;
  display: flex;
  border: 1px solid rgb(187, 187, 187);
  align-items: center;
  padding: 8px 0px;
  justify-content: space-evenly;
}

.coupon-txt-box-1 {
  width: 43%;
  font-size: 13px;
}

.coupon-txt-box-2 {
  width: 65%;
  font-size: 12px;
  font-weight: 600;
  color: rgb(87, 87, 87);
  line-height: 17px;
}

.coupon-off-button {
  width: 75px;
  height: 25px;
  background-color: #535353;
  color: white;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.coupon-searchbox {
  height: 45px;
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.coupon-box-container {
  height: 380px;
  overflow: scroll;
  border: 1px solid rgb(238, 238, 238);
  padding: 15px 5px 5px 5px;
  border-radius: 15px;
}

.coupon-box-container::-webkit-scrollbar {
  display: none;
}

.inputBox_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 50%;
  height: fit-content;
  background-color: #ffffff;
  border-radius: 0.8em;
  overflow: hidden;
  border: 1px dashed rgb(223, 223, 223);
}

.search_icon {
  height: 1em;
  padding: 0 0.5em 0 0.8em;
  fill: #abb2bf;
}

.inputBox {
  background-color: transparent;
  color: #000000;
  outline: none;
  width: 100%;
  border: 0;
  padding: 0.5em 1.5em 0.5em 0;
  font-size: 13px;
}

::placeholder {
  color: #818792;
}

.search-coupon-btn-1 {
  height: 29px;
  width: 100px;
  /* background-color: $Theme-color; */
  background: linear-gradient(180deg, #4480ff 0%, #115dfc 50%, #0550ed 100%);
  border: none;
  color: white;
  border-radius: 8px;
}

.coupon-image-1 {
  width: 20%;
}

.coupon-image-2 {
  width: 15%;
}

.coupon-image-3 {
  width: 35px;
}

.coupon-apply-btn-1 {
  border: none;
  width: 60px;
  height: 30px;
  /* background-color: $Theme-color; */
  background: linear-gradient(180deg, #4480ff 0%, #115dfc 50%, #0550ed 100%);
  color: white;
  font-size: 11px;
  border-radius: 15px;
  cursor: pointer;
  border: 1px solid rgb(187, 187, 187);
  display: flex;
  align-items: center;
  justify-content: center;
}

.coupon-apply-btn-1:hover {
  background-color: white;
  /* color: $Theme-color; */
}

.opencoupon-btn {
  position: absolute;
  right: 20px;
  top: 10px;
  border: none;
  padding: 10px 15px;
  background: linear-gradient(180deg, #4480ff 0%, #115dfc 50%, #0550ed 100%);
  color: white;
  font-size: 12px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  z-index: 1;
}

/* .category-main{
 display: flex  ;
 cursor: pointer;
}

.sub-category-box{

  margin: 5px;
  margin-left: 20px;
  cursor: pointer;
}

.sub-category-txt{
display: flex;

}

.child-category-box{
  margin-left: 50px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
} */

.loader {
  width: 4.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

.loader-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  flex-direction: column;
}

.loader-box p {
  font-size: 20px;
  color: rgba(61, 61, 61, 0.97);
}

.loader-circle {
  fill: none;
  stroke: rgba(61, 61, 61, 0.97);
  stroke-width: 4;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 3s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}

.addaddress_input {
  background-color: #b1b1b100;
  border: none;
  border-bottom: 1px solid rgba(55, 65, 81, 1);
  color: rgba(156, 163, 175, 1);
  width: 100%;
}

.addaddress_input:focus {
  outline: none;
}

.addaddress_lable {
  color: white;
  font-size: 13px;
  margin-right: 30px;
  margin-bottom: 3px;
  color: rgba(156, 163, 175, 1);
}

.select-address {
  width: 100%;
  background-color: #111827;
  border: none;
  border-bottom: 1px solid rgba(55, 65, 81, 1);
  color: rgba(156, 163, 175, 1);
}

.addaddress_radio_box {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.addaddress_radio {
  margin: 0;
  margin-right: 5px;
}

.address-flex-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 20px 0px 20px 0px;
  border: 1px solid #4d4d4d8f;
}

.address-flex-box div {
  display: flex;
  width: 43%;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.addaddres-bottom_box {
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
}

.cart-icon-utensile {
  margin-left: 05px;
  font-size: 15px;
}

.variableproduct-modal-back {
  position: fixed;
  width: 100%;
  height: 1500vh;
  top: 0;
  left: 0;
  background: rgba(14, 14, 14, 0.253);
  animation: modalback 500ms;
  z-index: 100;
}

.variableproduct-modal {
  width: 45%;
  height: max-content;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 52%;
  left: 50%;
  height: max-content;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -60%);
  /* animation: modalslide 700ms; */
  z-index: 1;
  text-align: center;
  padding-bottom: 10px;
}

.VP-heading {
  font-size: 18px;
  font-weight: 600;
  color: #282c3f;
  text-align: left;
  display: flex;
  align-items: center;
}

.VP-heading-1 {
  font-size: 16px;
  font-weight: 600;
  color: #282c3f;
  text-align: left;
  margin-bottom: 5px;
}

.VP-heading-2 {
  display: flex;
  font-size: 16px;
  color: #282c3f;
  text-align: left;
  font-weight: 300;
}

.vp-price-box {
  display: flex;
  font-size: 15px;
  align-items: center;
  margin-left: 15px;
  /* font-weight: 100; */
  color: #686868;
}

.vp-price-box i {
  color: #686868 !important;
  font-size: 13px;
  margin-top: 10px;
}

.VP-heading-2:hover {
  /* font-size: 16px; */
  color: rgb(41, 41, 41);
  cursor: pointer;
  font-weight: 600;
}

.vp-heading-box {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px dashed rgb(197, 197, 197);
}

.vp-heading-box i {
  font-size: 12px;
  margin: 10px;
  color: #0f8a65;
}

.vp-add-btn {
  width: 97%;
  margin: auto;
  background-color: rgba(38, 151, 4, 0.966);
  height: 35px;
  color: white;
  font-size: 16px;
  border: none;
  margin-top: 10px;
  font-weight: 600;
}

.vp-modal-content {
  height: max-content;
  padding: 15px 30px;
  overflow: scroll;
  scrollbar-width: none;
  height: 400px
}

.vp-modal-content::-webkit-scrollbar {
  display: none;
}

/* .vp-modal-content-main{

} */

.vp-modal-content-main div {
  margin: 4px 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vp-modal-content-main .quantity-box {
  flex-direction: column;
}

.vp-modal-content-main div input {
  /* margin-right: 8px; */
  /* margin-bottom: 3px; */
}

/* 
.vp-modal-content-main div label{

} */


.Variable_productname {
  width: 65%;
}

.variable-cross {
  position: absolute;
  right: 13px;
  top: 14px;
  font-size: 21px;
  color: rgb(66, 66, 66);
  cursor: pointer;
}

.mycart-empty-txt-box {
  position: absolute;
  width: 300px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -3%);
  text-align: center;
}

.mycart-empty-txt-box-1 {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  height: 400px;
}

.mycart-empty-txt-box-1 .mycart-empty-image {
  width: 25%;
  margin-bottom: 15px;
}

.mycart-empty-txt {
  text-align: center;
  color: rgb(131, 131, 131);
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.mycart-empty-txt-2 {
  text-align: center;
  color: rgb(75, 75, 75);
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}

.mycart-empty-image {
  width: 55%;
  margin-bottom: 15px;
}

.vp-price-data {
  color: gray;
  width: 200px;
  float: right;
  text-align: center;
}

.mycart-file-box {
  font-size: 11px;
  font-weight: 600;
  color: #e20606;
  margin: 0;
  margin-top: 5px;
}

.mycart-suggestion-box {
  border: none;
  border-bottom: 1px solid rgb(221, 221, 221);
  padding: 5px 0px;
  font-size: 12px;
}


.mycart-suggestion-box:focus {
  outline: none;
}

.products-cart-box-1 {
  width: 100%;
  display: flex;
  align-items: center;
}

.ourmenu-productnotavli {
  color: rgb(255, 0, 0);
  font-size: 11px;
  font-weight: 600;
  margin: 0;
  margin-top: 1px;
}

.mycart-productnotavli {
  color: rgb(240, 0, 0) !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  margin: 0 !important;
  margin-top: 3px !important;
}

.dfjcc {
  display: flex;
  width: 30%;
  flex-direction: column;
  padding-left: 15px;
}

.mycart-quantitybox {
  width: 35%;
  display: flex;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 20px;
}

.mycart-deletebtn {
  color: rgb(117, 117, 117);
  font-size: 16px;
  cursor: pointer;
  padding-left: 10px;
}

.mycart-productname {
  width: 30%;
}


.nofavproduct {
  text-align: center;
  font-size: 15px;
  margin: auto;
}

.InnerPTag p {
  font-size: 13px !important;
  margin-bottom: 5px !important;
}

.InnerPTag {
  font-size: 13px !important;
  margin-bottom: 5px !important;
}

.cart-number-pc {
  position: absolute;
  top: -5px;
  font-size: 12px;
  right: -15px;
  background-color: rgba(255, 255, 255, 0);
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-number-phone {
  position: absolute;
  top: 2px;
  font-size: 12px;
  right: 23px;
  background-color: rgba(255, 255, 255, 0);
  width: 15px;
  height: 15px;
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
}

/* ************************* Toshika ************************* */
.toastDiv {
  position: fixed;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: #fff;
  padding: 10px 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 999;
  width: 90%;
  display: flex;
  justify-content: center;
}

/* styles.css */

/* The container */
.label-1 {
  display: block;
  position: relative;
  padding-left: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 10px;
  padding: 0px !important;
  text-align: start;
  width: 100% !important;
}

/* Hide the browser's default radio button */
.label-1 .input-1 {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
label input:checked~.checkmark {
  background-color: transparent;
}

/* Create the tick mark */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the tick mark when the radio button is checked */
.label-1 input:checked~.checkmark:after {
  display: block;
}

/* Style the tick mark (using borders) */
.label-1 .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.addressDiv {
  border: 1px solid #4d4d4d8f;
  border-radius: 5px;
  margin: 5px 0px;
  padding: 10px;
}

/* ****************** */
.text-between-lines {
  display: flex;
  align-items: center;
}

.line2 {
  flex-grow: 1;
  height: 0px;
  background-color: #89909c;
  margin: 0 10px;
  width: 100%
}

.text {
  font-size: 16px;
  font-weight: bold;
  width: auto
}

/* ************************** */
.input-container {
  position: relative;
  width: 98%;
}

.input-with-image {
  width: 100%;
  padding: 5px;
}

.input-btn {
  position: absolute;
  top: 50%;
  right: -13px;
  transform: translateY(-50%);
  width: auto;
  height: 28px;
  background-color: darkgreen;
  color: lightgray;
  font-weight: 500;
  border: 1px solid transparent;
}

/* ******************************** */
.modal-back1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.72);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.map-modal {
  width: 600px;
  height: auto;
  border: 1px solid rgb(94, 94, 94);
  color: rgb(0, 0, 0);
  background-color: white;
  border-radius: 15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: modalslide 1000ms;
  padding: 10px;
}

.map-style {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
}

.confirmLocation {
  background-color: darkgreen;
  color: white;
  padding: 10px;
  width: 90%;
  margin: 20px;
  border: 1px solid white;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
}

.delAddressSection {
  max-height: 280px;
  overflow-y: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  border: 1px solid white;
  padding: 10px;
}

.image-input-container {
  position: relative;
  display: inline-block;
}

.input-image {
  position: absolute;
  top: 42%;
  right: 6px;
  transform: translateY(-50%);
  width: 30px;
  height: auto;
}

/* *************** Track Order Status ***************** */
.icon-white {
  color: white;
}

.space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.space-around {
  display: flex;
  justify-content: space-around;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  /*padding: 5px 2px;*/
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.heading {
  background-color: black;
  padding: 10px;
  position: sticky;
  top: 0;
  width: 100%;
}

.head-display {
  display: flex;
  justify-content: space-between;
}

.head-title {
  margin-left: 8px;
  font-size: 16px !important;
  font-weight: 600;
  color: white;
}

.ques {
  font-weight: 700;
  font-size: 13px;
  padding: 2px 0px;
  font-family: Calibri;
}

.ans {
  /*margin-left: 5px;*/
  font-weight: 400;
  font-size: 14px;
  padding: 2px 0px;
  font-family: Calibri;
}

.iconDiv {
  margin-right: 5px;
  padding: 20px;
  border: 1px solid rgb(94, 94, 94);
  color: white;
  background-color: dimgray;
  border-radius: 10px;
  /*width: 8%;*/
  padding-top: 15px;
  font-size: 22px;
  height: 25px
}

.statusDiv {
  width: 96%;
  height: auto;
  background-color: white;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 10px;
  padding-top: 10px;
  padding: 5px 10px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.redBtn {
  color: white;
  background-color: rgb(231, 55, 55);
  padding: 10px;
  border: 1px solid white;
  border-radius: 5px;
  width: 50%;
  margin-top: 10px;
}

.addaddress_input::-webkit-inner-spin-button,
.addaddress_input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.addaddress_input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

/* Optionally, to remove the cursor */
.addaddress_input {
  cursor: default;
}

.input1::-webkit-inner-spin-button,
.input1::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input1[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

/* Optionally, to remove the cursor */
.input1 {
  cursor: default;
}

.mobileMenu {
  display: grid;
  align-content: center;
}


.ShowHideIcon {
  position: absolute;
  right: 24px;
  top: 38px;
  color: #a3a3a3;
}


.sendOtpButton {
  color: #000000;
  margin: 0;
  font-size: 12px;
  width: 25%;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  display: flex;
  border-radius: 10px;
  margin-left: 10px;
}

.resendotpbtn {
  /* position: absolute; */
  cursor: pointer;
  margin: 0px;
  font-weight: 600;
  background: none;
  border: none;
  color: rgb(148, 123, 221);
  /* left: 28px;
    top: 115px; */

}


.address-icon-1 {
  color: #4f4f4f;
  font-size: 12px;
  margin-right: 3px;

}

.image-for-big {
  border-radius: 8px;
  height: 60px;
  width: 60px;
  margin: 5px 10px;
}

.quantityintosalepricemycart {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: space-between;
  color: #515151;
}


.mycart-itemcontainer {
  max-height: 515px;
  overflow: scroll;
}

.mainModalAddress_Scroll {
  overflow-y: scroll;
  height: 105px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollpart-cart-2::-webkit-scrollbar {
  display: none;
}

.mycart-file-box button {
  background-color: white;
  border: none;
  cursor: pointer;
  color: rgb(7, 7, 7);
}


.suggestion-box-image {
  width: 550px;
  transform: translate(-50%, -50%);
  top: 30%;
  position: fixed;
  left: 50%;
}

/* *********** Psyment Response Page ***************** */
.payment-success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #001f3f;
  /* Dark blue background */
  color: #ffffff;
  /* White text */
  font-family: 'Arial', sans-serif;
}

.success-message {
  text-align: center;
  margin-bottom: 20px;
}

.success-message h1 {
  color: #1dc81d;
}

.order-details {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.order-details p {
  margin: 10px 0;
  color: #004080;
}



/* From Uiverse.io by catraco */
/* Hide the default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  /* font-size: 14px; */
  user-select: none;
  border-radius: 50%;
  background-color: white;
  margin-right: 5px;
  width: 15px;
  height: 15px;
}

/* Create a custom checkbox */
.checkmark-1 {
  position: relative;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  transition: .3s;
  transform: scale(0);
  border-radius: 50%;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark-1 {
  background-color: rgba(167, 139, 250, 1);
  transform: scale(1);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-1:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark-1:after {
  display: block;
}

.container input:checked~.celebrate {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark-1:after {
  left: 0.40em;
  top: 0.20em;
  width: 0.30em;
  height: 0.45em;
  border: solid white;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
}

.container .celebrate {
  position: absolute;
  transform-origin: center;
  animation: kfr-celebrate .4s;
  animation-fill-mode: forwards;
  display: none;
  /* stroke: rgba(167, 139, 250, 1); */
  stroke: white;
}

@keyframes kfr-celebrate {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
    display: none;
  }
}

.head-title-ot {
  /* margin-left: 8px; */
  font-size: 16px;
  font-weight: 600;
  color: white;
  margin-bottom: 0;
  font-family: Calibri;
  text-align: center;
}

.greenBtn {
  background-color: #2a9f79;
  padding: 10px;
  border-radius: 10px;
  /* width: max-content !important; */
  color: white;
  font-weight: 600;
  border: 3px solid white;
}


.menu-card-detail p {
  margin: 0;
  font-size: 11px;
  color: #717171;
  line-height: 13px;
  margin-bottom: 2px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}


.collapsed p {
  -webkit-line-clamp: 1;
  /* Show only 1 line */
}

.expanded p {
  -webkit-line-clamp: unset;
  /* Show all lines */
}

.shortDescriptionBox {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.ShowMorebuttonPCM {
  background: white;
  border: none;
  padding: 0;
  font-size: 10px;
  color: #0217b5;
  font-weight: 600;
  /* text-decoration: underline; */
  /* width: 80px; */
  height: 12px;
  margin-bottom: 3px;
}

.favouriteBox{
  height: 500px;
  overflow-y: scroll;
  
}


/* Target scrollbars within elements with class 'favouriteBox' */
.favouriteBox::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.favouriteBox::-webkit-scrollbar-thumb {
  background: rgb(151, 151, 151); /* Color of the thumb */
  border-radius: 5px; /* Rounded corners to match the width */
}

.favouriteBox::-webkit-scrollbar-thumb:hover {
  background: #757575; /* Slightly lighter black on hover */
}

.favouriteBox::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the track (optional) */
}