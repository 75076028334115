@media screen and (max-width: 1100px) {

  .navbar-content {
    display: none;
  }

  .navbar {
    height: 50px;
  }


  .navbar-mobile {
    display: flex;
    margin: auto;
    width: 95%;
    justify-content: space-between;
    height: 100%;
    align-items: center;
  }

  .icon-box-nav {
    display: flex;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .mobile-nav-icon {
    padding: 10px;
    font-size: 16px;
    color: black;
  }

  .mobile-logo-box {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .opennavbar-mobile {
    width: 15%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }


  .ourmenu-topdiv {
    top: -2px;
  }


  .mobile-sidebar {
    display: flex;
    width: 320px;
    height: 100vh;
    background-color: #393939f5;
    position: absolute;
    z-index: 11;
    position: fixed;
    transition: transform 700ms ease;
    transform: translateX(-320px);

  }

  .mobile-sidebar.show {
    transform: translateX(0);
  }



  .slide-in {
    animation: slide 300ms ease forwards;
  }

  @keyframes slide {
    from {
      left: -300px;
      /* Adjust the starting left position */
    }

    to {
      left: 0;
      /* Adjust the ending left position */
    }
  }

  .account-modal {
    width: 40%;
    height: max-content;

  }

  .sign {
    padding: 0.4rem;
  }

  .move-animation {
    transition: transform 0.8s ease;
  }

  .move-animation-2 {
    transition: transform 1s ease-out;
  }


  .sidebar-link-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-right: 1px solid rgb(214, 214, 214);

  }



  .sidebar-logo {
    height: 75px;
    display: flex;
    border-bottom: 1px solid rgb(214, 214, 214);
    align-items: center;
    color: white;
    font-size: 18px;
    padding-left: 10px;
  }




  .navbar-option-link-txt {
    font-size: 14px;
    padding: 10px 0px;
    padding-left: 20px;
    text-align: start;
    border-bottom: 1px solid rgb(214, 214, 214);
    color: white;
    font-weight: 100;
  }

  .navbar-option-link-txt:active {
    background-color: black;
  }


  .back-content {
    width: 100%;
    height: 100%;
  }

  .sidebar-icon-link {
    padding-right: 15px;
  }

  .cart .products .product span {
    font-size: 12px;
    margin-top: 6px;
    /* text-align: center; */
  }

  .cart .products .product {
    width: 95%;
    padding: 10px;
  }

  .cart .products .product p {
    font-size: 10px;
  }

  .price {

    font-size: 14px;
  }

  .cart .quantity label {
    font-size: 11px;
  }


  .cart .quantity label {
    width: 25px;

  }

  .card-cart {
    width: 100% !important;
    margin-top: 10px;
    padding: 5px;
    /* height:  450px !important; */
    box-shadow: 5px 5px 10px rgb(231, 231, 231);
    margin-bottom: 0px;

  }

  .add-to-cart-button-fav {
    border: none;

    font-size: 12px;
    background-color: white;
    color: navy;
  }

  .myfav-txt {
    font-size: 11px;
  }

  th {
    font-size: 10px;
  }

  .favroit-table {
    margin-top: 5px;
    width: 98%;
  }
  .my-order-table {
    height: 155px;
    /* height: 140px; */
    width: 100%;
  }

  .image-slider {
    height: 60vh;
  }

  .quantity {
    justify-content: center;
  }

  .card-cart-product {
    padding: 5px;
    width: 98%;
    height: 50px;
  }

  .product-category {
    font-size: 12px;
  }

  .catogory-box {
    padding: 0 25px;
  }


  .card-menu-1 {
    width: 94%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    transition: all;
    flex-direction: column;
    align-items: center;
  }

  .menu-image-box {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 90%;
    flex-direction: column;
    margin-top: 10px;
  }


  .menu-image-box-1 {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 90%;
    flex-direction: column-reverse;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 100px;
  }

  .card-menu {
    height: max-content;
  }

  /* .card-menu-favorite {
  position: absolute;
  bottom: 82px;
  right: 20px;
  font-size: 17px;
  top: auto;
} */

  .menu-card-title-name {
    font-size: 12px;
    padding: 0;
  }

  .card-cart-txt-2 {
    font-size: 12px;
    display: none;
    color: rgb(109, 109, 109);
  }

  .menu-card-title-name {
    text-align: center;
  }

  /* .menu-card-detail{
  text-align: center;

} */


  .menu-card-price {
    text-align: center;

  }

  .card-cart-txt-1 {
    font-size: 11px !important;
  }

  .card-cart-txt-3 {
    font-size: 11px !important;

  }

  .scrollpart-cart {

    background-size: 50% 25%;
  }

  .cartpage-service-options {
    width: 29%;
  }

  .cartoage-input-box p {
    font-size: 11px !important;
  }

  .service-type-paragraph {
    font-size: 9px;
  }

  .sidebarback {
    width: 100%;
    height: 150vh;
    background-color: rgba(0, 0, 0, 0.301);
    position: fixed;
    z-index: 11;
  }


  .navbar-close-btn {
    position: absolute;
    font-size: 23px;
    top: 18px;
    right: 10px;
    color: white;
  }

  .cartpage-service-options-2 {
    width: 43%;

  }

  .mycart-table-order-row {
    width: 90%;
  }

  .cartpage-service-options-box {
    display: flex;
    flex-direction: column;
  }

  .cartpage-service-options {
    width: 95%;
    height: max-content;
    margin-bottom: 10px;
  }

  .cartoage-input-box {
    flex-direction: row;
    align-items: center;
  }

  .service-type-paragraph {
    margin-left: 0px;
  }

  .cartpage-service-options-1 {
    width: 95%;
  }

  .cartpage-service-options-1 input {
    margin-right: 10px;
  }


  .cartpage-service-options input {
    margin-right: 10px;
  }

  .cartoage-input-box-1 {
    align-items: center;
    flex-direction: row;
  }

  .form-container-1 {
    width: 40%;
  }

  .form-container-2 {
    width: 50%;
  }

  .fs-m {
    justify-content: flex-start;
    margin-left: 15px;
  }

  .mobile-service-selection {
    font-size: 13px;
    position: absolute;
    left: 18%;
    background-color: white;
    border: none;
  }



}